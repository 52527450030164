<template>
  <el-dialog
    :title="$t('layout.changePassword')"
    :visible.sync="showPasswordDialog"
    destroy-on-close
    width="400px">
    <div v-loading="loading">
      <el-form
        ref="form"
        :rules="rules"
        :model="formData"
        label-width="auto"
        size="medium">
        <el-form-item :label="$t('layout.oldPassword')" prop="oldPassword">
          <el-input type="password" v-model.trim="formData.oldPassword" :placeholder="$t('layout.oldPasswordPlaceholder')"/>
        </el-form-item>
        <el-form-item :label="$t('layout.newPassword')" prop="newPassword">
          <el-input type="password" v-model.trim="formData.newPassword" :placeholder="$t('layout.newPasswordPlaceholder')"/>
        </el-form-item>
        <el-form-item :label="$t('layout.confirmPassword')" prop="confirmPassword">
          <el-input type="password" v-model.trim="formData.confirmPassword" :placeholder="$t('layout.confirmPasswordPlaceholder')"/>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <el-button size="medium" type="primary" @click="handleConfirm" :loading="loading">{{$t('button.confirm')}}</el-button>
      <el-button size="medium" @click="showPasswordDialog = false">{{$t('button.cancel')}}</el-button>
    </template>
  </el-dialog>
</template>
<script>
import {showErrorMsg, showSuccessMsg} from "@/utils/func";
import {save} from "@/api/user";
import {logoutSuccess} from "@/utils/login";

export default {
  name: "ChangePasswordDialog",
  data() {
    return {
      showPasswordDialog: false,
      formData: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        oldPassword: [{required: true, message: this.$t('layout.oldPasswordPlaceholder'), trigger: 'blur'}],
        newPassword: [{required: true, message: this.$t('layout.newPasswordPlaceholder'), trigger: 'blur'}, {validator: this.validateNewPassword}],
        confirmPassword: [{required: true, message: this.$t('layout.confirmPasswordPlaceholder'), trigger: 'blur'}, {validator: this.validateConfigPassword}],
      },
      loading: false
    }
  },

  computed: {
    userInfo() {
      return this.$store.state.user.info || {}
    }
  },

  methods: {
    start() {
      this.formData = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      }
      this.showPasswordDialog = true
    },

    validateNewPassword(rule, value, callback) {
      if (value && this.formData.oldPassword && value === this.formData.oldPassword) {
        callback(this.$t('layout.newPasswordError'))
      } else {
        callback()
      }
    },

    validateConfigPassword(rule, value, callback) {
      if (value && this.formData.newPassword && value !== this.formData.newPassword) {
        callback(this.$t('layout.confirmPasswordError'))
      } else {
        callback()
      }
    },

    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.oldPassword !== this.userInfo.password) {
            showErrorMsg(this.$t('message.title'), this.$t('layout.oldPasswordError'))
            return
          }
          this.loading = true
          save({
            id: this.userInfo.id,
            type: this.userInfo.type,
            password: this.formData.newPassword
          }).then(res => {
            showSuccessMsg(this.$t('message.title'), this.$t('layout.changePasswordSuccess'))
            this.showPasswordDialog = false
            setTimeout(() => {
              logoutSuccess()
            }, 3000)
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>