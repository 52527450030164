<template>
  <el-dropdown @command="handleLocalChange" placement="bottom">
    <i class="el-icon-_language" style="font-size: 18px"/>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="zh">{{$t('language.zh')}}</el-dropdown-item>
      <el-dropdown-item command="en">{{$t('language.en')}}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>

export default {
  name: 'ChangeLang',
  data() {
    return {
    }
  },
  mounted() {
  },
  methods:{
    handleLocalChange(locale){
      this.$i18n.locale = locale
      localStorage.setItem('locale', locale)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/var";

.el-dropdown-link {
  color: $--color-primary;
  cursor: pointer;
}
</style>