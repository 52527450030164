import Vue from 'vue'
import VueRouter from "vue-router"
import NProgress from 'nprogress' // progress bar
import config from '@/config/index'
import store from '@/store'
import staticRoutes from "@/router/staticRoutes";
import {firstGetSecondGo} from "@/router/firstGetAndGo";
import {getToken} from "@/utils/func";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
let {tokenWhiteList, loginPath} = config

const router = new VueRouter({
  routes: staticRoutes
})

router.beforeEach((to, from, next) => {

  // 进度条
  NProgress.start()
  // web标题
  to.meta && to.meta.title && (window.document.title = to.meta.title)

  if (tokenWhiteList.includes(to.name)) {
    // 去的是token白名单

    next()
  } else {
    // 去的不是token白名单

    if (getToken()) {
      if (store.state.user.info) {
        // 登录状态下 - 并且已加载过个人菜单
        next()
      } else {
        // 登录状态下 - 并且未加载过个人菜单
        firstGetSecondGo(to, from, next)
      }
    } else {
      // 未登录状态-去的是需要登录才能访问的页面
      next({path: loginPath, query: {redirect: to.fullPath}})
    }
  }
})

router.afterEach(() => {
  // 进度条
  NProgress.done()
})

export function resetRouter() {
  router.matcher = new VueRouter({
    routes: staticRoutes
  }).matcher
}


export default router
