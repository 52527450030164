import Vue from 'vue'

Vue.directive(
  'drag', {
    inserted: (el, binding, vnode) => {
      const target = el


      target.dataset.left = target.style.left
      target.dataset.top = target.style.top

      target.style.position = 'absolute'
      target.addEventListener('mouseover', function () {
        // target.style.cursor = 'move'
        // target.style.border = '1px dashed red'
      })
      target.addEventListener('mouseout', function () {
        // target.style.border = ''
      })

      el.onmousedown = (e) => {
        const disX = e.pageX - target.offsetLeft
        const disY = e.pageY - target.offsetTop
        document.onmousemove = (de) => {
          target.style.left = de.pageX - disX + 'px'
          target.style.top = de.pageY - disY + 'px'

          target.dataset.left = target.style.left
          target.dataset.top = target.style.top

          let position = {
            top: target.dataset.top,
            left: target.dataset.left
          }
          vnode.data.on.changePosition(position)
        }
        document.onmouseup = () => {
          document.onmousemove = document.onmouseup = null
          vnode.data.on.changePositionFinish()

        }
      }
    }
  })