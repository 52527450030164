<template>
  <el-dialog
    :title="$t('layout.profile')"
    :visible.sync="showDialog"
    destroy-on-close
    width="800px">
    <el-descriptions :column="1" border :labelStyle="{width: '100px'}">
      <el-descriptions-item :label="$t('user.account')">{{userInfo.account}}</el-descriptions-item>
      <el-descriptions-item :label="$t('user.name')">{{userInfo.name}}</el-descriptions-item>
      <el-descriptions-item :label="$t('user.realName')">{{userInfo.realName}}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>
<script>

export default {
  name: "ProfileInfoDialog",
  
  data() {
    return {
      showDialog: false,
      loading: false
    }
  },
  
  computed: {
    userInfo() {
      return this.$store.state.user.info || {}
    }
  },
  
  methods: {
    start() {
      this.showDialog = true
    }
  }
}
</script>