import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "@/components/lang";
import ElementUI from 'element-ui';

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

// 引入公共css样式
import '@/assets/style/index.scss'
import '@/assets/style/component-icon.css'
import '@/assets/style/xzd-icon.css'
import 'bootstrap/dist/css/bootstrap-utilities.css'
import 'bootstrap/dist/css/bootstrap-grid.css'

import '@/utils/directive'

import '@/components/index'

import '@/assets/icons/index'

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

Vue.prototype.$perm = function(perms) {
  let marks = store.state.user.marks
  if (Array.isArray(perms)) {
    return marks.some(mark => perms.includes(mark))
  } else {
    return marks.some(mark => mark === perms)
  }
}


import {setLogoutFunc} from '@/utils/request'
import {loginSuccess, logoutSuccess} from "@/utils/login";
Vue.prototype.$loginSuccess = loginSuccess
setLogoutFunc(logoutSuccess)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

