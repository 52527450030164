<template>
  <div class="left-bar" :class="{'is-collapse': isCollapse}">
    <div class="left-bar-header">
<!--      <img class="logo" alt="xzd" :src="logoUrl"/>-->
      <div v-if="!isCollapse" class="brand">{{appName}}</div>
    </div>
    <el-scrollbar style="height: calc(100vh - 60px)">
      <el-menu
        :default-active="$route.path"
        :collapse="isCollapse">
        <menu-son :tree="tree" :collapse="isCollapse"/>
      </el-menu>
    </el-scrollbar>
  </div>
</template>
<script>
import MenuSon from "@/layout/components/menu/MenuSon";
import config from '@/config'
import logoUrl from '@/assets/logo.png'

export default {
  name: "MenuBox",
  components: {MenuSon},
  props: {
    tree: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      logoUrl
    }
  },
  computed: {
    isCollapse() {
      return this.$store.state.setting.menuCollapse
    },
    appName() {
      if (this.$i18n.locale === 'zh') {
        return config.appName
      } else {
        return config.appEnName
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/var";

.left-bar {
  border-right: $--border-base;
  height: 100vh;
  width: 256px;
  overflow: hidden;
  box-sizing: border-box;
  transition: width 0.3s;
  background-color: #191A23;
  
  .left-bar-header {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    
    .logo {
      width: 30px;
      height: 30px;
    }
    
    .brand {
      color: white;
      margin-left: 10px;
      font-size: 20px;
    }
  }
  
  .el-menu {
    border: 0;
    width: 100%;
    overflow: hidden;
    background-color: #191A23;
    
    ::v-deep {
      
      .el-menu {
        background-color: #191A23;
      }
      
      .el-submenu > .el-submenu__title,
      .el-menu-item {
        color: rgba(255, 255, 255, 0.8) !important;
        height: 45px;
        line-height: 45px;
      }
      
      .el-submenu > .el-submenu__title i,
      .el-menu-item i {
        color: inherit;
      }
  
      .el-submenu > .el-submenu__title:hover,
      .el-menu-item:hover {
        color: white !important;
        background-color: transparent;
      }
  
      .el-menu-item.is-active,
      ul:not(.el-menu--popup) > .el-menu-item.is-active {
        background: $--color-primary !important;
      }
    }
  }
  
  &.is-collapse {
    width: 60px;
    
    ::v-deep {
      .el-submenu__icon-arrow {
        display: none;
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/style/var";

.menu-box-popper {
  .el-menu {
    background-color: #191A23 !important;
  
    .el-submenu > .el-submenu__title,
    .el-menu-item {
      color: rgba(255, 255, 255, 0.8) !important;
      height: 45px;
      line-height: 45px;
    }
  
    .el-submenu > .el-submenu__title i,
    .el-menu-item i {
      color: inherit;
    }
  
    .el-submenu > .el-submenu__title:hover,
    .el-menu-item:hover {
      color: white !important;
      background-color: transparent;
    }
  
    .el-menu-item.is-active,
    ul:not(.el-menu--popup) > .el-menu-item.is-active {
      background: $--color-primary !important;
    }
  }
}
</style>