import router, {resetRouter} from '@/router'
import store from '@/store'
import {setToken, showSuccessMsg} from "@/utils/func";

export const loginSuccess = (token, path) => {
  setTimeout(() => {
    setToken(token)
    router.push(path || '/')
    showSuccessMsg('提示', '登录成功')
  }, 500)
}

export const logoutSuccess = () => {
  setToken('')
  store.dispatch('user/logout')
  router.push('/login')
  resetRouter()
  showSuccessMsg('提示', '退出登录成功')
}