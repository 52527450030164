import  store from '@/store'
import {showErrorMsg} from "@/utils/func";
import {logoutSuccess} from "@/utils/login";
import router from "@/router/index";
import i18n from '@/components/lang'

export function firstGetSecondGo(to, from, next){
  store.dispatch('user/getLoginUserInfo').then(({routes})=> {
    router.addRoute(routes)
    router.addRoute({path: '*', name: 'Page404', component: () => import('@/views/common/Page404.vue')})

    // 请求带有 redirect 重定向时，登录自动重定向到该地址
    const redirect = decodeURIComponent(from.query.redirect || to.path)
    if (to.path === redirect) {
      next({ ...to, replace: true })
    } else {
      // 跳转到目的路由
      next({ path: redirect })
    }
  }).catch(err => {
    showErrorMsg(i18n.t('message.title'), i18n.t('message.getLoginUserInfoFail'))
    logoutSuccess()
  })
}