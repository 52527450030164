<template>
  <div>
    <div class="big-font">
      page-404
      <el-button type="text" @click="$router.push('/')">{{$t('page404.goHome')}}</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "page404"
}
</script>
<style lang="scss" scoped>
.big-font {
  padding: 40px;
  font-size: 40px;
  text-align: center;
}
</style>